import { postJson } from './http.js'

/**
 * 获取用工风险计算结果
 * @param {*} params
 */
export const getLaborRisk = data => postJson('/i/calculator/v1.0/labor_risk', data)

/**
* 获取社保计算 医疗保险 计算结果
* @param {*} params
*/
export const getMedicalInsurance = (data) => {
  return postJson(`/calc/v1.0/medical-insurance`, data)
}

/**
 * 获取社保计算 失业保险 计算结果
 * @param {*} params
 */
export const getUnemployedInsurance = data => postJson('/calc/unemployed/v1.0', data)

/**
 * 获取社保计算 生育保险 计算结果
 * @param {*} params
 */
export const getMaternityInsurance = data => postJson('/calc/v1.0/maternity-insurance', data)

/**
 * 获取社保计算 工伤保险 计算结果
 * @param {*} params
 */
export const getWorkInjuryInsurance = data => postJson('/calc/v1.0/work-jury-insurance', data)

/**
 * 获取基础养老计算 基础养老 计算结果
 * @param {*} params
 */
export const  siCalc = params => postJson('calc/si/v1.0', params)

/**
 * 个人养老金补缴计算 计算结果
 * @param {*} params
 */
export const calcPersonArrears = data => postJson('calc/v1.0/person-arrears', data)

/**
 * 单位养老金补缴计算 计算结果
 * @param {*} params
 */
export const calcCompanyArrears = data => postJson('calc/v1.0/company-arrears', data)



/**
 * 获取缴费基数
 * @param {*} params
 */
 export const baseFee = data => postJson('calc/v1.0/base-fee', data)

/**
 * 养老金趸交
 * @param {*} data 
 * @returns 
 */
export const calcMedicalPayInFull = data => postJson('calc/medical/v1.0/pay-in-full', data)

/**
 * 在职人员死亡待遇领取
 * @param {*} data 
 * @returns 
 */
export const calcAnnuityReturnOnJobDie = data => postJson('calc/si/v1.0/on-job-die', data)

/**
 * 领取待遇人员死亡待遇
 * @param {*} data 
 * @returns 
 */
export const calcAnnuityReturnRetireDie = data => postJson('calc/si/v1.0/retire-die', data)